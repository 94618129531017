import { LatLng } from "leaflet";

export interface ILandmark {
  name: string;
  pos: LatLng;
  icon: string;
  iconSize: [number, number];
  iconAnchor: [number, number];
  navAid: boolean;
  posArray: () => [number, number];
}

export class Landmark implements ILandmark {
  public name = "";
  public pos: LatLng = new LatLng(0, 0);
  public icon = "";
  public iconSize: [number, number] = [0, 0];
  public iconAnchor: [number, number] = [0, 0];
  public navAid = false;

  constructor(data: Partial<ILandmark>) {
    Object.assign(this, data);
  }

  posArray(): [number, number] {
    return [this.pos.lat, this.pos.lng];
  }
}
