import { LatLng } from "leaflet";

export interface IWaypoint {
  id: string;
  name: string;
  pos: LatLng;
  posArray: () => [number, number];
}

export class Waypoint implements IWaypoint {
  public id = "";
  public name = "";
  public pos: LatLng = new LatLng(0, 0);

  constructor(data: Partial<IWaypoint>) {
    Object.assign(this, data);
  }

  posArray(): [number, number] {
    return [this.pos.lat, this.pos.lng];
  }
}
