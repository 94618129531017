import { createRouter, createWebHistory } from "vue-router";
import MooringMap from "@/components/MooringMap.vue";

export default createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: MooringMap,
    },
    {
      path: "/list",
      component: () => import("@/components/MooringList.vue"),
    },
    {
      path: "/print",
      component: () => import("@/components/PrintMap.vue"),
    },
    {
      path: "/ic",
      component: () => import("@/components/InvitationalCupMap.vue"),
    },
  ],
});
