import { LatLng } from "leaflet";

export interface IMooring {
  name: string;
  pos: LatLng;
  color: string;
  type: string;
  size: number | null;
  owner: string;
  focus: boolean;
  waypoints: string[];
  bearings: string[];
  directions: string;
  posArray: () => [number, number];
  contains: (val: string) => boolean;
  addBearingFrom: (label: string, pos: LatLng) => void;
}

export class Mooring implements IMooring {
  public name = "";
  public pos: LatLng = new LatLng(0, 0);
  public color = "";
  public type = "";
  public size = null;
  public owner = "";
  public focus = false;
  public waypoints: string[] = [];
  public bearings: string[] = [];
  public directions = "";
  private earthRadiusM = 6372795.477598;
  private earthRadiusYa = 6969373.881887577;

  constructor(data: Partial<IMooring>) {
    Object.assign(this, data);
  }

  posArray(): [number, number] {
    return [this.pos.lat, this.pos.lng];
  }

  contains(val: string): boolean {
    return this.name.includes(val);
  }

  toRadians(degrees: number): number {
    return (degrees * Math.PI) / 180;
  }

  // Converts from radians to degrees.
  toDegrees(radians: number): number {
    return (radians * 180) / Math.PI;
  }

  roundDecimals(input: number, decimals: number): number {
    const factor = Math.pow(10, -1 * decimals);
    return Math.round(input / factor) * factor;
  }

  bearingDist(start: LatLng, dest: LatLng): [number, number] {
    const startLat = this.toRadians(start.lat);
    const startLng = this.toRadians(start.lng);
    const destLat = this.toRadians(dest.lat);
    const destLng = this.toRadians(dest.lng);

    const y = Math.sin(destLng - startLng) * Math.cos(destLat);
    const x =
      Math.cos(startLat) * Math.sin(destLat) -
      Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
    let brng = Math.atan2(y, x);
    brng = this.toDegrees(brng);
    brng = (brng + 360) % 360;

    const distLat = this.toRadians(dest.lat - start.lat) / 2;
    const distLng = this.toRadians(dest.lng - start.lng) / 2;
    const a =
      Math.sin(distLat) * Math.sin(distLat) +
      Math.cos(startLat) *
        Math.cos(destLat) *
        Math.sin(distLng) *
        Math.sin(distLng);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = this.earthRadiusYa * c;

    return [Math.round(brng), this.roundDecimals(d, -1)];
  }

  addBearingFrom(label: string, fromPos: LatLng) {
    const [brng, dist] = this.bearingDist(fromPos, this.pos);
    this.bearings.push(`${dist} yd from ${label} at bearing ${brng}\u00B0`);
  }
}
