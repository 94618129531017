import { ILandmark, Landmark } from "@/models/Landmark";
import { LatLng } from "leaflet";

export const Landmarks: ILandmark[] = [];
Landmarks.push(
  new Landmark({
    name: "Harbour Court",
    pos: new LatLng(41.4746, -71.3271),
    icon: "img/nyyc.png",
    iconSize: [51, 31],
    iconAnchor: [0, 0],
  })
);
Landmarks.push(
  new Landmark({
    name: "Oliver Hazzard Perry",
    pos: new LatLng(41.4789, -71.3348),
    icon: "img/ohp.png",
    iconSize: [48, 50],
    iconAnchor: [24, 50],
  })
);
Landmarks.push(
  new Landmark({
    name: "Sail Newport\nHoist",
    pos: new LatLng(41.4742, -71.3361),
    icon: "img/sn_hoist.png",
    iconSize: [33, 48],
    iconAnchor: [11, 48],
  })
);
Landmarks.push(
  new Landmark({
    name: "Alofsin Pier\nFort Adams Ferry Dock",
    pos: new LatLng(41.477617, -71.33445),
    icon: "img/ferry.png",
    iconSize: [46, 40],
    iconAnchor: [23, 0],
  })
);
Landmarks.push(
  new Landmark({
    name: "Ida Lewis YC",
    pos: new LatLng(41.4774, -71.3258),
    icon: "img/ilyc.png",
    iconSize: [51, 28],
    iconAnchor: [0, 0],
  })
);
Landmarks.push(
  new Landmark({
    name: "Newport Harbor Buoy 4",
    pos: new LatLng(41.481167, -71.335033),
    icon: "img/bouy_red.png",
    iconSize: [28, 25],
    iconAnchor: [13, 22],
    navAid: true,
  })
);
