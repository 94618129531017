import { LatLng } from "leaflet";

function cardinal(card: number) {
  switch (card) {
    case 1:
      return "N";
    case -1:
      return "S";
    case -2:
      return "W";
    case 2:
      return "E";
  }
}

export function degreePosDMS(value: LatLng): string {
  return (
    decimalPos2DMS(value.lat, true) + "\n" + decimalPos2DMS(value.lng, false)
  );
}

export function degreePosDDM(value: LatLng): string {
  return (
    decimalPos2DMS(value.lat, true) + "\n" + decimalPos2DMS(value.lng, false)
  );
}

export function decimalPos2DMS(pos: number, lat: boolean): string {
  const posValue = Math.abs(pos);
  const posDegree = Math.trunc(posValue);

  const posMinutesDecimal = posValue - posDegree;
  const posMinutes = Math.trunc(60 * posMinutesDecimal);
  const posSeconds = Math.round(60 * (60 * posMinutesDecimal - posMinutes));

  return (
    String(posDegree).padStart(2, "0") +
    "\u00B0 " +
    String(posMinutes).padStart(2, "0") +
    "\u2032 " +
    String(posSeconds).padStart(2, "0") +
    "\u2033 " +
    cardinal(Math.sign(pos) * (lat ? 1 : 2))
  );
}

export function decimalPos2DDM(pos: number, lat: boolean): string {
  const posValue = Math.abs(pos);
  const posDegree = Math.trunc(posValue);

  const posMinutesDecimal = posValue - posDegree;
  const posMinutes = posMinutesDecimal * 60;

  return (
    String(posDegree).padStart(2, "0") +
    "\u00B0 " +
    String(posMinutes.toFixed(3)).padStart(2, "0") +
    "\u2032 " +
    cardinal(Math.sign(pos) * (lat ? 1 : 2))
  );
}
