export const FieldMask = [
  [41.4797, -71.3339],
  [41.4772, -71.3341],
  [41.4752, -71.3343],
  [41.4753, -71.3356],
  [41.4749, -71.3362],
  [41.4746, -71.3362],
  [41.4744, -71.3359],
  [41.4738, -71.3362],
  [41.4735, -71.3368],
  [41.4726, -71.3368],
  [41.4716, -71.3362],
  [41.4706, -71.3364],
  [41.47, -71.3366],
  [41.4697, -71.3361],
  [41.4704, -71.335],
  [41.471, -71.3352],
  [41.4714, -71.3346],
  [41.472, -71.3343],
  [41.4721, -71.3332],
  [41.4716, -71.3331],
  [41.4732, -71.3296],
  [41.4737, -71.3285],
  [41.4743, -71.3288],
  [41.4746, -71.3275],
  [41.4751, -71.3275],
  [41.4758, -71.3252],
  [41.4771, -71.3262],
  [41.4776, -71.3263],
  [41.4778, -71.3257],
  [41.4773, -71.3242],
  [41.4776, -71.3238],
  [41.4775, -71.3233],
  [41.4768, -71.3233],
  [41.4771, -71.3221],
  [41.4795, -71.3184],
  [41.48, -71.3182],
  [41.481, -71.3182],
  [41.4803, -71.3305],
];
