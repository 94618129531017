import { ref } from "vue";
import type { Ref } from "vue";
import { Mooring } from "@/models/Mooring";
import { Waypoint } from "@/models/Waypoint";
import { LatLng } from "leaflet";

export function useMoorings() {
  const data: Ref<Mooring[]> = ref([]);
  const waypoints: Ref<Waypoint[]> = ref([]);
  const error = ref(null);

  const mooringTable = "tblKfsQJEdnrt4a1d";
  const waypointTable = "tbl33R7zx3yQlOtkG";
  const airBase = process.env.VUE_APP_AIR_BASE;
  const airToken = process.env.VUE_APP_AIR_TOKEN;

  const mooringFetch = fetch(
    `https://api.airtable.com/v0/${airBase}/${mooringTable}`,
    {
      headers: { Authorization: `Bearer ${airToken}` },
    }
  )
    .then((res) => res.json())
    .then((json) => {
      data.value = (json.records as Record<string, unknown>[])
        .filter((r: any) => {
          return r.fields.Type === "nyyc";
        })
        .map((r: any) => {
          return new Mooring({
            name: r.fields.Name,
            pos: new LatLng(r.fields.Lat, r.fields.Lng),
            color: r.fields.Color,
            focus: false,
            type: r.fields.Type,
            size: r.fields.Length,
            owner: r.fields.Owner,
            waypoints:
              typeof r.fields.Waypoints === "undefined"
                ? []
                : r.fields.Waypoints,
            directions: r.fields.Directions,
          });
        })
        .sort((a, b) => a.name.localeCompare(b.name));
    })
    .catch((err) => (error.value = err));

  const waypointFetch = fetch(
    `https://api.airtable.com/v0/${airBase}/${waypointTable}`,
    {
      headers: { Authorization: `Bearer ${airToken}` },
    }
  )
    .then((res) => res.json())
    .then((json) => {
      waypoints.value = (json.records as Record<string, unknown>[]).map(
        (r: any) => {
          return new Waypoint({
            id: r.id,
            name: r.fields.Name,
            pos: new LatLng(r.fields.Lat, r.fields.Lng),
          });
        }
      );
    })
    .catch((err) => (error.value = err));

  Promise.all([mooringFetch, waypointFetch]).then(() => {
    data.value.forEach((m) => {
      waypoints.value
        .filter(
          (w) => m.waypoints.length === 0 || m.waypoints.indexOf(w.id) !== -1
        )
        .forEach((w) => {
          m.addBearingFrom(w.name, w.pos);
        });
    });
  });

  return { data, error };
}
